import { useDeckmatchUserStore } from '@/stores/deckmatchUser'
import { useCIStore } from '@/stores/ciStore'
// import { useAuth0, authGuard } from '@auth0/auth0-vue';
export default defineNuxtRouteMiddleware(async (to, from) => {
  const deckmatchUser = useDeckmatchUserStore()
  const ciStore = useCIStore()
  const error = useError()
  // console.log(error);
  if(error.value && error.value.statusCode === 401){
    return navigateTo('login?message=Please log in again')
  }
  
  if(deckmatchUser.getMeta.status === 'no_access'){
    return navigateTo(`/login?errorMessage=${deckmatchUser.getMeta.error?.statusMessage}&errorCode=${deckmatchUser.getMeta.error?.statusCode}`);
  }

  
    if(deckmatchUser.getMeta.status === 'not_fetched' ){
      await deckmatchUser.fetchUser()
      await ciStore.fetchVerdict(deckmatchUser.getClientInvestorId)
  }
});